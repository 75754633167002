const middleware = {}

middleware['checkout'] = require('../middleware/checkout.js')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['router/check-auth'] = require('../middleware/router/check-auth.js')
middleware['router/check-auth'] = middleware['router/check-auth'].default || middleware['router/check-auth']

middleware['router/invitation-referrals'] = require('../middleware/router/invitation-referrals.js')
middleware['router/invitation-referrals'] = middleware['router/invitation-referrals'].default || middleware['router/invitation-referrals']

middleware['router/public-reservation'] = require('../middleware/router/public-reservation.js')
middleware['router/public-reservation'] = middleware['router/public-reservation'].default || middleware['router/public-reservation']

middleware['router/user-agent'] = require('../middleware/router/user-agent.js')
middleware['router/user-agent'] = middleware['router/user-agent'].default || middleware['router/user-agent']

middleware['server/block-by-country'] = require('../middleware/server/block-by-country.js')
middleware['server/block-by-country'] = middleware['server/block-by-country'].default || middleware['server/block-by-country']

middleware['server/invitation-redirects'] = require('../middleware/server/invitation-redirects.js')
middleware['server/invitation-redirects'] = middleware['server/invitation-redirects'].default || middleware['server/invitation-redirects']

middleware['server/landing-redirects'] = require('../middleware/server/landing-redirects.js')
middleware['server/landing-redirects'] = middleware['server/landing-redirects'].default || middleware['server/landing-redirects']

middleware['server/post-data'] = require('../middleware/server/post-data.js')
middleware['server/post-data'] = middleware['server/post-data'].default || middleware['server/post-data']

middleware['server/product-redirects'] = require('../middleware/server/product-redirects.js')
middleware['server/product-redirects'] = middleware['server/product-redirects'].default || middleware['server/product-redirects']

middleware['server/public-reservation'] = require('../middleware/server/public-reservation.js')
middleware['server/public-reservation'] = middleware['server/public-reservation'].default || middleware['server/public-reservation']

middleware['server/search-redirects'] = require('../middleware/server/search-redirects.js')
middleware['server/search-redirects'] = middleware['server/search-redirects'].default || middleware['server/search-redirects']

middleware['server/static-redirects'] = require('../middleware/server/static-redirects.js')
middleware['server/static-redirects'] = middleware['server/static-redirects'].default || middleware['server/static-redirects']

export default middleware
