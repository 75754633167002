// eslint-disable-next-line import/no-relative-parent-imports
import { PRODUCT_PERMALINK_ROUTER } from '../../utils/static-router-alias'

const axios = require('axios')

/** https://csabaszabo.dev/blog/how-to-handle-301-redirects-with-nuxtjs/ */
export default async (req, res, next) => {
  const language = process.env.APP_LOCALE.toLowerCase().split('_')[0]

  /** listado de posibles types en todos los idiomas de la plataforma */
  const list = [].concat.apply(
    [],
    Object.values(PRODUCT_PERMALINK_ROUTER.groups)
  )

  const groups = await req.url.split('?')
  const url = groups[0].endsWith('/') ? groups[0].slice(0, -1) : groups[0]
  const sections = url.split('/')
  sections.shift()

  const currentType = PRODUCT_PERMALINK_ROUTER.base[sections[0]]
  let newType = null
  let newLocation = null
  const currentSlug = sections[1]
  let erLegacy = null

  if (list.includes(currentType) && !/.+\.(png|jpg|jpeg|svg)/.test(url)) {
    switch (sections[0]) {
      case 'experience':
        erLegacy = /\/experience\/[^/]+(\/(for-me|whats-included|faqs|extras)?)?/
        break
      case 'package':
        erLegacy = /\/package\/[^/]+(\/(for-me|whats-included|faqs|extras)?)?/
        break
      default:
        break
    }

    if (erLegacy !== null && erLegacy.test(url)) {
      newType = PRODUCT_PERMALINK_ROUTER.alias[language][sections[0]]
    }

    if (newType === null) {
      newType = sections[0]
    }

    newLocation = `/${newType}/${currentSlug}`

    /** aceptando AB Testing en las rutas */
    if (
      sections.length > 2 &&
      PRODUCT_PERMALINK_ROUTER.abtesting.includes(sections[2])
    ) {
      newLocation += `/${sections[2]}`
    }

    // Initiate axios
    const instance = axios.create({
      baseURL: process.env.LAD_PRIVATE_API_BASE_URL,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'X-localization': process.env.APP_LOCALE,
        'X-currency': process.env.APP_CURRENCY,
      },
    })

    try {
      const {
        data: { data, status },
      } = await instance.post('/api/settings/validate-slug', {
        type: currentType,
        slug: currentSlug,
        url,
      })

      if (status === 'success') {
        if (newLocation === null) {
          newLocation = url
        }

        const current = [data.current.es, data.current.en]

        if (!current.includes(currentSlug)) {
          newLocation = newLocation.replace(
            `/${currentSlug}`,
            `/${data.current.default}`
          )
        }
      }
    } catch (error) {}

    if (newLocation !== url) {
      if (groups.length === 2) {
        newLocation += '?' + groups[1]
      }

      res.writeHead(301, { Location: newLocation })
      return res.end()
    } else {
      return next()
    }
  } else {
    return next()
  }
}
