const REDIRECTS = [{ from: '/invitation/', to: '/', code: 301, isValid: true }]

/** https://csabaszabo.dev/blog/how-to-handle-301-redirects-with-nuxtjs/ */
export default (req, res, next) => {
  const url = req.url.split('?')[0]
  const urlParams = req.url.includes('?') ? '?' + req.url.split('?')[1] : null
  const redirect = REDIRECTS.find((t) => url.startsWith(t.from) && t.isValid)

  if (redirect) {
    const newLocation = urlParams ? redirect.to + urlParams : redirect.to

    res.writeHead(redirect.code, { Location: newLocation })
    return res.end()
  } else {
    return next()
  }
}
